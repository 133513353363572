/* purgecss start ignore */
h1 {
    @apply text-5xl uppercase font-semibold;
}

h2 {
    @apply text-4xl uppercase font-semibold;
}

h3 {
    @apply text-3xl uppercase font-semibold;
}

h4 {
    @apply text-2xl uppercase font-semibold;
}

h5 {
    @apply text-xl uppercase font-semibold;
}
/* purgecss end ignore */